import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import UsedTech from "../components/UsedTech"

import "../components/Layout.css"
import "../components/List.css"

import DesignCirclePartImage from "../images/mask@3x.png"
import EllipseImage from "../images/ellipse.svg"
import EnmeBgComponent from "../images/enme-bg-component.svg"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  hearYourEmployeesTitle,
  hearYourEmployeesList,
  engagedEmployeesTitle,
  engagedEmployeesList,
  solutionImage,
  solutionImage2,
  resultTitle,
  resultImage,
  resultDescription,
  solutionAdditionalInfo,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative lg:flex container mx-auto py-24 pb-12 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180 hidden md:block"
          style={{ left: "35%", top: "5px" }}
        />
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10 pb-8 md:pb-24">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3 mt-10 lg:mt-0 sm:w-3/4">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="mx-auto w-7/8"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div className="bg-gray-200 relative overflow-hidden">
        <img
          src={EllipseImage}
          alt=""
          className="hidden md:block absolute w-4/5 top-0 -left-40 z-10"
        />
        <div className="container mx-auto py-20 px-16 lg:px-32 relative">
          <img
            src={DesignCirclePartImage}
            alt=""
            style={{ bottom: "460px", right: "170px" }}
            className="hidden md:block absolute w-24 transform -rotate-90"
          />
          <div className="md:flex md:flex-row pb-10">
            <div className="z-20 w-full md:w-2/3">
              <img
                src={solutionImage.childImageSharp.fixed.src}
                alt=""
                className="md:-ml-20"
              />
            </div>
            <div className="z-20 md:w-1/3">
              <h2 className="font-semibold text-3xl mb-8">
                {hearYourEmployeesTitle}
              </h2>
              <ul className="list-default leading-8 pl-5 text-gray-500">
                {hearYourEmployeesList.map((item, i) => (
                  <li key={i} className="pl-4">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:flex md:flex-row">
            <div className="z-20 md:w-2/5">
              <h2 className="font-semibold text-3xl mb-8">
                {engagedEmployeesTitle}
              </h2>
              <ul className="list-default leading-8 pl-5 text-gray-500">
                {engagedEmployeesList.map((item, i) => (
                  <li key={i} className="pl-4">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className="md:w-2/3 md:mb-0 z-20">
              <img
                src={solutionImage2.childImageSharp.fixed.src}
                alt=""
                className="md:-mt-16"
              />
            </div>
          </div>
        </div>
      </div>

      {/* SOLUTION BLOCK*/}
      <div className="bg-circle relative container mx-auto py-12 md:py-20 px-16 lg:px-32">
        <img
          src={EnmeBgComponent}
          alt=""
          style={{ top: "-350px", left: "-290px" }}
          className="hidden md:block absolute w-4/5 z-10"
        />
        <div className="md:flex flex-row justify-end relative">
          <img
            src={resultImage.childImageSharp.fixed.src}
            alt=""
            className="relative md:absolute fit-with-text z-20"
          />

          <div className="md:flex flex-col md:w-1/2">
            <h2 className="font-semibold text-3xl mb-8">{resultTitle}</h2>
            <p className="my-10 text-gray-500">{resultDescription}</p>
            <p className="my-10 text-gray-500">{solutionAdditionalInfo}</p>
          </div>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <UsedTech title={title} AppDev SoftDev UiUx />
    </div>
  )
}

const Enme = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={project.html}
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default Enme

export const pageQuery = graphql`
  query EnmeID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        hearYourEmployeesTitle
        hearYourEmployeesList
        engagedEmployeesTitle
        engagedEmployeesList
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionImage2 {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultTitle
        resultDescription
        solutionAdditionalInfo
        resultItems
      }
    }
  }
`
